import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { filter, map, Observable, of, Subscription, switchMap } from 'rxjs';
import { UserRole } from 'src/app/model/user-model';
import { ApplicationToolbarService } from 'src/app/service/application-toolbar.service';
import { LoginUserComponent } from '../login-user/login-user.component';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { HasRolesDirective } from '../../modules/shared/directives/has-roles.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { CollapsedAppToolbarDirective } from '../../modules/shared/directives/collapsed-app-toolbar.directive';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    standalone: true,
    imports: [MatToolbarModule, CollapsedAppToolbarDirective, NgIf, MatButtonModule, MatMenuModule, MatIconModule, NgFor, HasRolesDirective, RouterLinkActive, RouterLink, NgTemplateOutlet, LoginUserComponent, AsyncPipe]
})
export class AppHeaderComponent implements OnInit,OnDestroy {

  toolbarTemplate$: Observable<TemplateRef<any>|undefined>;
  showAppTitle$: Observable<boolean>;

  showNavButtons: boolean = false;
  showCollapsedNav: boolean = false;
  showExtendedNav: boolean = false;

  private subs = new Subscription();

  globalMenuLinks: {
    label: string,
    route: string[],
    requiredRoles?: UserRole[]
  }[] = [{
    label: "Programs",
    route: ['/programs']
  },{
    label: "Progressions",
    route: ['/progressions']
  },{
    label: "Exercises",
    route: ['/exs']
  },{
    label: "Gallery",
    route: ['/gallery']
  }];

  constructor(
    private applicationToolbar: ApplicationToolbarService,
    private breakpointObserver: BreakpointObserver) 
  {
    this.toolbarTemplate$ = this.applicationToolbar.getToolbarTemplate();
    this.showAppTitle$ = this.applicationToolbar.showAppTitle();
  }

  ngOnInit(): void {
    const sub = this.applicationToolbar.showNavButtons().pipe(switchMap((showNavButtons)=>{
      if (!showNavButtons) {
        return of([false,false]);
      } else {
        return this.breakpointObserver.observe(Breakpoints.XSmall)
          .pipe(map((isXSmall)=>[isXSmall.matches,!isXSmall.matches]));
      }
    }))
    .subscribe(([showSmallMenu,showExtendedMenu])=>{
      this.showCollapsedNav = showSmallMenu;
      this.showExtendedNav = showExtendedMenu;
      this.showNavButtons = this.showCollapsedNav || this.showExtendedNav;
    });
    this.subs.add(sub);
    /*
    this.applicationToolbar.collapseExpandToolbar$.subscribe(ce=>{
      console.log("collapseExpandToolbar ",ce);
    });
    */
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}

