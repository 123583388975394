<mat-toolbar color="primary" class="main-app-toolbar" collapsedAppToolbar>

  <ng-container *ngIf="showCollapsedNav">
      <button mat-icon-button [matMenuTriggerFor]="navMenu">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #navMenu="matMenu">
        <ng-container *ngFor="let link of globalMenuLinks">
          <button mat-menu-item *hasRoles="link.requiredRoles" [routerLink]="link.route" routerLinkActive="mat-accent">
            {{link.label}}
          </button>
        </ng-container>
      </mat-menu>
  </ng-container>

    <h1 *ngIf="showNavButtons" 
      class="link" [routerLink]="['/home']">
      tProgs
      <span class="beta-badge">BETA</span>
    </h1>

    <ng-container *ngIf="showExtendedNav">
        <ng-container *ngFor="let link of globalMenuLinks">
          <a mat-button *hasRoles="link.requiredRoles" [routerLink]="link.route" routerLinkActive="mat-accent">
              {{link.label}}
          </a>
        </ng-container>
    </ng-container>

    <div class="toolbar-tamplate-wrapper">
      <ng-container *ngIf="toolbarTemplate$ | async as template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>

    <app-login-user></app-login-user>

  </mat-toolbar>