import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { appRoutes } from './app/app-routing.module';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LOGIN_SERVICE_INITIALIZER } from './app/service/login.service';
import { FIREBASE_PROVIDERS } from './app/firebase';
import { GlobalErrorHandler } from './app/support/global-error-handler';
import { provideRouter ,withInMemoryScrolling } from '@angular/router';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      MatDialogModule,
      MatSnackBarModule
    ),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    FIREBASE_PROVIDERS,
    LOGIN_SERVICE_INITIALIZER,
    provideRouter(appRoutes),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch(err => console.error(err));
