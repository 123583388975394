import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Directive, ElementRef, NgZone } from '@angular/core';
import { ApplicationToolbarService } from 'src/app/service/application-toolbar.service';

@Directive({
  selector: '[appAppHeaderScrollController]',
  standalone: true
})
export class AppHeaderScrollControllerDirective extends CdkScrollable {

  constructor(
      elementRef: ElementRef<HTMLElement>, 
      scrollDispatcher: ScrollDispatcher, 
      ngZone: NgZone,
      private appToolbarService: ApplicationToolbarService) 
  {
    super(elementRef, scrollDispatcher, ngZone);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.appToolbarService.removedScrollController(this);
  }

}
