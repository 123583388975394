import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserRole } from 'src/app/model/user-model';
import { LoginService } from '../../../service/login.service';

@Directive({
    selector: '[hasRoles]',
    standalone: true
})
export class HasRolesDirective implements OnInit, OnChanges, OnDestroy {
  
  @Input('hasRoles')
  requiredRoles?: UserRole[];
  
  private userRoles: ReadonlySet<UserRole> = new Set();

  private subs = new Subscription();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    const sub = this.loginService.getLoginUser().subscribe(user => {
      this.userRoles = new Set(user.roles);
      this.updateView();
    });
    this.subs.add(sub);
  }
  
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (changes['requiredRole']) {
      this.updateView();
    }
  }
  
  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    if (!this.requiredRoles) {
      return true;
    }
    for (const requiredRole of this.requiredRoles) {
      if (!this.userRoles.has(requiredRole)) {
        return false;
      }
    }
    return true;
  }
}