import { Component } from '@angular/core';

@Component({
    selector: 'app-actions-panel',
    templateUrl: './actions-panel.component.html',
    styleUrls: ['./actions-panel.component.scss'],
    standalone: true
})
export class ActionsPanelComponent {

}
