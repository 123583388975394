import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApplicationToolbarService } from 'src/app/service/application-toolbar.service';

@Directive({
    selector: '[collapsedAppToolbar]',
    standalone: true
})
export class CollapsedAppToolbarDirective implements OnInit,OnDestroy {

  private subs = new Subscription();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private applicationToolbar: ApplicationToolbarService) {}

  ngOnInit(): void {
    const sub = this.applicationToolbar.collapseExpandToolbar$.subscribe(collapsed=>{
      if (collapsed) {
        this.elementRef.nativeElement.classList.add("collapsedAppToolbar");
      } else {
        this.elementRef.nativeElement.classList.remove("collapsedAppToolbar");
      }
    });
    this.subs.add(sub);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
