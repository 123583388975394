import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { ActionsPanelComponent } from '../../modules/shared/components/actions-panel/actions-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    standalone: true,
    imports: [MatButtonModule, RouterLink, MatIconModule, ActionsPanelComponent, NgOptimizedImage]
})
export class HomePageComponent {

  openPayPalDonate() {
    const url = new URL("https://www.paypal.com/donate/");
    url.searchParams.append('business','GYSDNWKBKXF7G');
    url.searchParams.append('amount','1.99');
    url.searchParams.append('no_recurring','0');
    url.searchParams.append('item_name','Thanks to your action you will allow tProgs to grow, evolve and offer new features!');
    url.searchParams.append('currency_code','EUR');
    window.open(url,"paypal");
   // https://www.paypal.com/donate/?business=GYSDNWKBKXF7G&amount=1.99&no_recurring=0&item_name=Grazie+al+tuo+gesto+permetterai+a+tProgs+di+crescere%2C+evolvere+ed+offrire+nuove+funzionalit%C3%A0%21&currency_code=EUR
  }
}
