import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { 
  Auth, 
  User, 
  GoogleAuthProvider, 
  //FacebookAuthProvider, 
  //GithubAuthProvider,
  //TwitterAuthProvider,
  EmailAuthProvider
} from 'firebase/auth';
import { doc, Firestore, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { FIREBASE_AUTH, FIRESTORE } from '../firebase';
import { GUEST_USER, LoginUser, LoginUserImpl } from '../model/user-model';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    @Inject(FIREBASE_AUTH) private firebaseAuth: Auth,
    @Inject(FIRESTORE) private firestore: Firestore
  ) {
    console.log("LoginService service created");
  }

  private currentUser$ = new BehaviorSubject<LoginUser|null>(null);

  /**
   * Login User il always present (if not logged is a GUEST_USER instance)
   */
  getLoginUser(): Observable<LoginUser> {
    return this.currentUser$.pipe(filter(Boolean));
  }

  /**
   * This is always present (if not logged is a GUEST_USER instance)
   */
  get currentUser(): LoginUser {
    return this.currentUser$.getValue()!;
  }

  get isLogged(): boolean {
    return this.currentUser.isLogged;
  }

    /**
   * This could be undefined if user has not sign in into app (i.e. is a GUEST)
   */
  get currentLoggedUser(): User | undefined {
    return this.currentUser.user;
  }
  
  get currentLoggedUserName(): string {
    return this.currentLoggedUser?.displayName!;
  }

  get currentLoggedUserId(): string|undefined {
    return this.currentLoggedUser?.uid;
  }

  logout() {
    this.firebaseAuth.signOut();
  }

  initialize() {
    console.log("on init");
    return new Promise((resolve,reject)=>{
      this.firebaseAuth.onAuthStateChanged(async user => {
        if (!user) {
          console.log("not logged ");
          this.currentUser$.next(GUEST_USER);
        } else {
          console.log("logged ", user);
          const appLoggedUser = await this.checkCreateAppLoggedUser(user);
          this.currentUser$.next(appLoggedUser);
        }
        resolve(user);
      },reject);
    });
  }

  renderLoginUI(element: Element) {
    import('firebaseui').then(firebaseui=>{
      var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.firebaseAuth);
      ui.start(element, {
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: false
          },
          GoogleAuthProvider.PROVIDER_ID,
          //provider: FacebookAuthProvider.PROVIDER_ID,
          //GithubAuthProvider.PROVIDER_ID,
          //TwitterAuthProvider.PROVIDER_ID
        ],
        popupMode: true,
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: (authResult: any,redirectUrl?: string) => {
            console.log("signInSuccessWithAuthResult",authResult,redirectUrl);
            return false;
          }
        }
        // Other config options...
      });
    });
  }

  private async checkCreateAppLoggedUser(user: User): Promise<LoginUser> {
    const appLoggedUserRef = doc(this.firestore, 'users', user.uid);
    const appLoggedUserDocSnap = await getDoc(appLoggedUserRef);
    const nowDate = new Date();

    if (!appLoggedUserDocSnap.exists()) {
      const appLoggedUser = new LoginUserImpl(user,{
        firstLogin: nowDate,
        lastLogin: nowDate,
        roles: ['DEFAULT']
      });
      await setDoc(appLoggedUserRef,{
        id: appLoggedUser.id,
        userName: appLoggedUser.displayName,
        firstLogin: appLoggedUser.firstLogin,
        lastLogin: appLoggedUser.lastLogin,
        roles: appLoggedUser.roles
      });
      return appLoggedUser;
    } else {
      const data = appLoggedUserDocSnap.data();
      const appLoggedUser = new LoginUserImpl(user,{
        firstLogin: data['firstLogin'],
        lastLogin: data['lastLogin'],
        roles: data['roles']
      });
      await updateDoc(appLoggedUserRef,{
        id: appLoggedUser.id,
        lastLogin: nowDate
      });
      return appLoggedUser;
    }
  }

}


export const LOGIN_SERVICE_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: (loginService: LoginService) => (() => loginService.initialize()),
  deps: [LoginService],
  multi: true
};
