
<button mat-stroked-button  
    [matMenuTriggerFor]="loginProgidersMenu" 
    (menuOpened)="onSignInMenuOpened()">
    <mat-icon>arrow_drop_down</mat-icon>
    Sign In
</button>
<mat-menu #loginProgidersMenu="matMenu">
    <div style="min-width: 200px;" #loginUiContainer stopEventsPropagation="click" >
    </div>
</mat-menu>
