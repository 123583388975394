export const environment = {
  firebase: {
    apiKey: "AIzaSyAm7SNPTG4By9lWaImVAi7AGbIyAldyvII",
    authDomain: "tprogs-53e9a.firebaseapp.com",
    projectId: "tprogs-53e9a",
    storageBucket: "tprogs-53e9a.appspot.com",
    messagingSenderId: "179271540627",
    appId: "1:179271540627:web:5b8333cf866e0530cf1266",
    measurementId: "G-JLGMWKQELP"
  },
  production: true,
  buildVersion: /*@@<<buildVersion*/28/*>>@@*/,
  buildTm:      /*@@<<buildTm*/"2023-04-10T07:22:04.856Z"/*>>@@*/
};
