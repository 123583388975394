import { Component, ElementRef, Inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppHeaderComponent } from './components/app-header/app-header.component';

import { Analytics } from 'firebase/analytics';
import { FIREBASE_ANALYTICS } from './firebase';
import { CollapsedAppToolbarDirective } from './modules/shared/directives/collapsed-app-toolbar.directive';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [AppHeaderComponent, RouterOutlet, CollapsedAppToolbarDirective]
})
export class AppComponent {

  title = 't-progs';

  constructor(
    @Inject(FIREBASE_ANALYTICS) public analytics: Analytics,
    private elementRef: ElementRef) 
  {}

  onActivate() {
    (this.elementRef.nativeElement as HTMLElement).querySelector('.page')!.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

}
