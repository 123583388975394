import { Routes } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { UserHasRolesGuard } from './modules/shared/guards/user-has-roles.guard';

export const appRoutes: Routes = [
  { path: 'home', component: HomePageComponent },
  { 
    path: 'exs', 
    loadChildren: () => import('./modules/exercises-list/exercises-list-routing.module').then(m => m.routes) 
  },{ 
    path: 'programs', 
    loadChildren: () => import('./modules/programs-list/programs-list-routing.module').then(m => m.routes) 
  },{
    path: 'programs/:programId/view', 
    loadChildren: () => import('./modules/program-view/program-view-routing.module').then(m => m.routes)
  },{ 
    path: 'programs',
    loadChildren: () => import('./modules/program-edit/program-edit-routing.module').then(m => m.routes)
  },{
    path: 'programs/:programId/print', 
    loadChildren: () => import('./modules/program-print/program-print-routing.module').then(m => m.routes)
  },{ 
    path: 'programs/:programId/publish', 
    //canActivate: [UserHasRolesGuard],
    data: {
      requiredRoles: ["PUBLISHER"]
    },
    loadChildren: () => import('./modules/program-publishing/program-publishing-routing.module').then(m => m.routes)
  },{ 
    path: 'progressions',
    loadChildren: () => import('./modules/progressions/progressions-routing.module').then(m => m.routes) 
  },{ 
    path: 'gallery', 
    loadChildren: () => import('./modules/programs-gallery/programs-gallery-routing.module').then(m => m.routes)
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

