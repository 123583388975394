
<ng-container *ngIf="loginService.getLoginUser() | async as loginUser">
    <div *ngIf="loginUser.isLogged; else notLogged" class="logged-user-info">
        <button mat-fab [matMenuTriggerFor]="menu" class="image" 
            [style.backgroundImage]="'url(' + loginUser.photoURL + ')'">
            <mat-icon *ngIf="!loginUser.photoURL" class="material-symbols-outlined">person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <div class="username">
                {{loginUser.displayName}}
            </div>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
            <div class="dev-info-panel" *hasRoles="['ADMIN']">
                build: {{buildTm}}
            </div>
        </mat-menu>
    </div>
    <ng-template #notLogged>
        <app-login-action></app-login-action>
    </ng-template>
</ng-container>