import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor(private dialog: MatDialog,private snackBar: MatSnackBar) {}

  handleError(error: any): void {
    console.error(error);
    if (error instanceof ErrorWithMessage) {
      this.snackBar.open(error.message,"Close");
    } else if (error.rejection && error.rejection instanceof ErrorWithMessage) {
      this.snackBar.open(error.rejection.message,"Close");
    } else {
      this.snackBar.open(error,"Close");
    }
  }

}


export class ErrorWithMessage extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}