<div class="text-wrapper">
    <div class="text-section-container">
        <div class="text-section">
            <h2>Welcome to tProgs!</h2>
            
            <p>
            Are you a personal trainer in need of a better way to create and share training programs with your clients? <br>
            <b>tProgs</b> has you covered. <br>
            Our web application makes it easy to design and customize programs that are tailored to your clients' needs.
            </p>
            
            <p>
            With <b>tProgs</b>, you can easily drag and drop exercises into your program, 
            set sets and reps, and add notes and modifications for each exercise. <br>
            And, if you're looking to add new exercises to your repertoire, TProgs comes with a comprehensive exercise library 
            complete with photos and descriptions for hundreds of exercises.
            </p>
            
            <button class="follow-link" mat-stroked-button color="primary" [routerLink]="['/gallery']">
                See programs examples in the Gallery
                <mat-icon>
                    arrow_forward_ios
                </mat-icon>
            </button>
            
            <p>
            Once you've created your program, you have the option to brand it with your own logo and color scheme to give it a professional, 
            personalized touch. <br>
            And, when you're ready to share it with your client, simply print it in PDF format for easy sharing.
            </p>
            
            <p>
            Overall, <b>tProgs</b> is the ultimate tool for personal trainers looking to streamline their program design and tracking process. <br>
            Try it out <strong>Completely FREE</strong> today and see the difference it can make in your training business!
            </p>

        </div>    
    </div>
</div>

<app-actions-panel>
    <button mat-button color="primary" [routerLink]="['/programs']">
        Go To My Programs
        <mat-icon>
            arrow_forward_ios
        </mat-icon>
    </button>
    <button mat-button color="primary" [routerLink]="['/programs/create']">
        Start Create a new Program
        <mat-icon>
            add
        </mat-icon>
    </button>
</app-actions-panel>

<div class="text-wrapper">
    <div class="text-section-container">
        <div class="text-section">

            <h2>Beta Program</h2>
            
            <p>
            TProgs is currently on a Beta phase, it is completely free, but your data savings are stored in the browser memory storage.
            </p>
            
            <p>
                This means that in some cases it is possible that the data will be deleted, for example when clearing the browser cache. <br>
                Furthermore, by accessing the application through different browsers, only the data saved in each browser is visible.
            </p>

            <p>
                These limitations will be overcome when tProgs comes out of the beta phase, 
                in the meantime you can support us by signing in to the application and making a small donation (if you wish) 
                this will allow us to offer even more features in a faster time.
            </p>

        </div>

    </div>
</div>

<div class="footer-page">

    <img class="paypal-btn" 
        ngSrc="https://www.paypalobjects.com/en_US/IT/i/btn/btn_donateCC_LG.gif"
        name="payPal" 
        title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button"
        width="221"
        height="47"
        (click)="openPayPalDonate()">

</div>


