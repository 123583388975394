import { User } from "firebase/auth";

export interface LoginUser {

    readonly user: User | undefined;
    readonly isLogged: boolean;
    readonly displayName: string | null;
    readonly photoURL: string | null ;

    readonly firstLogin?: Date;
    readonly lastLogin?: Date;
    readonly roles : ReadonlyArray<UserRole>;

}

export type UserRole = "DEFAULT"|"ADMIN"|"PUBLISHER"|"DEVELOPER"; 

/**
 * User that has not done any signup (is not logged)
 * n.b. doesn't have any roles
 */
export const GUEST_USER: LoginUser = Object.freeze({
    user: undefined,
    isLogged: false,
    displayName: "Guest",
    photoURL: null,
    roles: Object.freeze([])
});

export class LoginUserImpl implements LoginUser {

    readonly user: User;
    readonly firstLogin?: Date;
    readonly lastLogin?: Date;
    private _roles : UserRole[];

    constructor(user: User,data: {
        firstLogin: Date;
        lastLogin: Date;
        roles: UserRole[];
    }) {
        this.user = user; 
        this.firstLogin = data.firstLogin;
        this.lastLogin = data.firstLogin;
        this._roles = data.roles;
    }

    get id(): string {
        return this.user.uid;
    }

    get isLogged(): boolean {
        return true;
    }

    get displayName(): string | null {
        return this.user.displayName || this.user.email;
    }

    get photoURL(): string | null {
        return this.user.photoURL;
    }

    get roles() : ReadonlyArray<UserRole> {
        return this._roles;
    }

}

