import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginService } from '../../service/login.service';
import { LoginActionComponent } from '../login-action/login-action.component';
import { HasRolesDirective } from '../../modules/shared/directives/has-roles.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-login-user',
    templateUrl: './login-user.component.html',
    styleUrls: ['./login-user.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule, HasRolesDirective, LoginActionComponent, AsyncPipe]
})
export class LoginUserComponent implements OnInit {

  buildTm = environment.buildTm;
  
  constructor(public loginService: LoginService) { }

  ngOnInit(): void {
  }

  logout() {
    this.loginService.logout();
  }

}
