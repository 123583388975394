
import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Analytics, getAnalytics } from 'firebase/analytics';
import { Firestore, getFirestore, initializeFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { InjectionToken } from "@angular/core";

import { environment } from "src/environments/environment";

export const FIREBASE_APP = new InjectionToken<FirebaseApp>("FirebaseApp");
export const FIREBASE_AUTH = new InjectionToken<Auth>("FirebaseAuth");
export const FIREBASE_ANALYTICS = new InjectionToken<Analytics>("FirebaseAnalytics");
export const FIRESTORE = new InjectionToken<Firestore>("Firestore");
export const FIREBASE_STORAGE = new InjectionToken<FirebaseStorage>("FirebaseStorage");

export const FIREBASE_PROVIDERS = [
  {
    provide: FIREBASE_APP,
    useFactory: () => initializeApp(environment.firebase)
  }, {
    provide: FIREBASE_AUTH,
    useFactory: (firebaseApp: FirebaseApp) => getAuth(firebaseApp),
    deps: [FIREBASE_APP]
  }, {
    provide: FIRESTORE,
    useFactory: (firebaseApp: FirebaseApp) => {
      console.log("intialize firestore");
      initializeFirestore(firebaseApp, {
        ignoreUndefinedProperties: true
      });
      return getFirestore(firebaseApp);
    },
    deps: [FIREBASE_APP]
  }, {
    provide: FIREBASE_STORAGE,
    useFactory: (firebaseApp: FirebaseApp) => getStorage(firebaseApp),
    deps: [FIREBASE_APP]
  }, {
    provide: FIREBASE_ANALYTICS,
    useFactory: (firebaseApp: FirebaseApp) => getAnalytics(firebaseApp),
    deps: [FIREBASE_APP]
  }
];