import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-login-action',
    templateUrl: './login-action.component.html',
    styleUrls: ['./login-action.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule]
})
export class LoginActionComponent {

  @ViewChild('loginUiContainer')
  loginUiContainer: ElementRef | undefined;
  private rendered: boolean = false;

  constructor(private loginService: LoginService) { }

  onSignInMenuOpened() {
    if (this.rendered) {
      return;
    }
    this.rendered = true;
    this.loginService.renderLoginUI(this.loginUiContainer!.nativeElement);
  }

}
