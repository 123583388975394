import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Injectable, TemplateRef } from '@angular/core';
import { nanoid } from 'nanoid';
import { BehaviorSubject, filter, map, merge, Observable, Subject, SubscriptionLike, tap } from 'rxjs';
import { AppHeaderScrollControllerDirective } from '../modules/shared/directives/app-header-scroll-controller.directive';


export type ToolbarSettings = {
  template?: TemplateRef<any>;
  showNavButton?: boolean;
  showAppTitle?:boolean;
};

type ApplyedToolbarSettings = ToolbarSettings & { id: string; };

@Injectable({
  providedIn: 'root'
})
export class ApplicationToolbarService {

  private toolbarSettingsQueue : ApplyedToolbarSettings[] = [];

  private toolbarTemplate$ = new BehaviorSubject<TemplateRef<any>|undefined>(undefined);
  private showNavButtons$ = new BehaviorSubject<boolean>(true);
  private showAppTitle$ = new BehaviorSubject<boolean>(true);

  private resetCollapseExpandToolbar$ = new Subject<boolean>();
  public collapseExpandToolbar$ = new BehaviorSubject<boolean>(false);

  private appliedSettingsId?: string;
  private scheduleReset: any;
  private currentController: any;
  
  constructor(private scrollDispatcher: ScrollDispatcher) {
    const scrollCollapseExpand = this.scrollDispatcher.scrolled(50)
      .pipe(
        filter(isScrolledControllerEvent),
        tap(evt=>this.currentController=evt),
        map(evt=>evt.measureScrollOffset('top')),
        //tap(topOffset=>console.log("collapseExpandToolbar offset",topOffset)),
        map(topOffset=>topOffset>5)
      );
    /*
      .pipe(
        filter(isScrolledControllerEvent),
        map(evt=>evt.measureScrollOffset('top')),
        pairwise(),
        map(evt=>evt[1]-evt[0]),
        //tap(offset=>console.log("collapseExpandToolbar offset",offset)),
        filter(offset=>Math.abs(offset)>10),
        map(offset=>offset>0),
        debounceTime(250)
      );
      */
     merge(this.resetCollapseExpandToolbar$,scrollCollapseExpand)
      //.pipe(tap(v=>console.log("collapseExpandToolbar ",v)))
      .subscribe(c=>{
        this.collapseExpandToolbar$.next(c);
      })
    //this.subs.add(scrollSub);
  }
  
  getToolbarTemplate(): Observable<TemplateRef<any>|undefined> {
    return this.toolbarTemplate$;
  }

  pushToolbarSettings(cfg: ToolbarSettings): SubscriptionLike {

    const settingId = nanoid();
    const applyed = Object.assign({},cfg,{
      id: settingId
    });
    this.toolbarSettingsQueue.push(applyed);

    this.checkRefreshAppliedSettings();
  
    let closed = false;
    return {
      unsubscribe: ()=>{
        this.removeToolbarSettings(applyed.id);
        closed = true;
      },
      get closed() {
        return closed;
      }
    };
  }

  private removeToolbarSettings(settingsId: string) {
    const idx = this.toolbarSettingsQueue.findIndex(s=>s.id===settingsId);
    if (idx!==-1) {
      this.toolbarSettingsQueue.splice(idx,1);
      this.checkRefreshAppliedSettings();
    }
  }

  private checkRefreshAppliedSettings() {
    if (this.scheduleReset) {
      clearTimeout(this.scheduleReset);
    }
    const len = this.toolbarSettingsQueue.length;
    const applied = len>0?this.toolbarSettingsQueue[len-1]:null;
    if (applied && applied.id!==this.appliedSettingsId) {
      setTimeout(()=>{
        this.appliedSettingsId = applied.id;
        this.toolbarTemplate$.next(applied.template);
        this.showNavButtons$.next(applied.showNavButton ?? (!applied.template));
        this.showAppTitle$.next(applied.showAppTitle ?? (!applied.template));
      },0);
    } else {
      this.resetToolbarSettings();
    }
  }

  private resetToolbarSettings() {
    this.appliedSettingsId = undefined;
    this.toolbarTemplate$.next(undefined);
    this.showNavButtons$.next(false);
    this.showAppTitle$.next(false);
    this.resetCollapseExpandToolbar$.next(false);
    this.scheduleReset = setTimeout(()=>{
      this.showNavButtons$.next(true);
      this.showAppTitle$.next(true);
    },100);
  }

  showNavButtons() {
    return this.showNavButtons$;
  }

  showAppTitle() {
    return this.showAppTitle$;
  }

  removedScrollController(scrollController: AppHeaderScrollControllerDirective) {
    if (this.currentController===scrollController) {
      this.resetCollapseExpandToolbar$.next(false);
    }
  }

}

function isScrolledControllerEvent(evt: CdkScrollable|void): evt is AppHeaderScrollControllerDirective {
  return !evt?false:evt instanceof AppHeaderScrollControllerDirective;
}